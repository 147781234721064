import { createTheme, Theme } from '@material-ui/core/styles';

export const defaultTheme: Theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#22376d',
    },
    secondary: {
      main: '#66bd0a',
    },
  },
});
