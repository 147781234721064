import { Chip } from '@material-ui/core';
import { BaseValueType } from './types';

type SelectedChipProps<ValueType> = {
  value: ValueType;
};

const SelectedChip = <ValueType extends BaseValueType = BaseValueType>({
  value,
}: SelectedChipProps<ValueType>) => (
  <Chip
    variant="outlined"
    color="primary"
    size="small"
    key={value}
    label={value}
  />
);

export default SelectedChip;
