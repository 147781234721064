import { DataSetRequested } from '@app/slices/graph';
import {
  Card,
  Chip,
  Grid,
  LinearProgress,
  LinearProgressProps,
  styled,
  Typography,
} from '@material-ui/core';

const EntryCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(1),
}));

export type DataSetLegendEntryProps = {
  requestedDataSet: DataSetRequested;
};

const DataSetLegendEntry = ({
  requestedDataSet: {
    isFetching,
    params: { deviceId, tag },
  },
}: DataSetLegendEntryProps) => {
  const progressProps: LinearProgressProps = isFetching
    ? {}
    : { variant: 'determinate', value: 100 };
  return (
    <EntryCard
      color="red"
      variant="elevation"
      title={`${tag} data for ${deviceId}`}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Typography>{deviceId}:</Typography>
            </Grid>
            <Grid item>
              <Chip
                variant="outlined"
                color="primary"
                size="small"
                label={tag}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <LinearProgress {...progressProps} />
        </Grid>
      </Grid>
    </EntryCard>
  );
};

export default DataSetLegendEntry;
