import ChipContainer from './ChipContainer';
import SelectedChip from './SelectedChip';
import { BaseValueType } from './types';

export type SelectedChipListProps<ValueType> = {
  selectedValues: ValueType[];
};

const SelectedChipList = <ValueType extends BaseValueType = BaseValueType>({
  selectedValues,
}: SelectedChipListProps<ValueType>) => (
  <ChipContainer>
    {selectedValues.map((value) => (
      <SelectedChip key={value} value={value} />
    ))}
  </ChipContainer>
);

export default SelectedChipList;
