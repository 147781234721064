import { Container, Grid } from '@material-ui/core';
import { WithChildren } from '../types';
import Header from './Header';

export type LayoutProps = WithChildren<{}>;

const Layout = ({ children }: LayoutProps) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Header />
      </Grid>
      <Grid item>
        <main>
          <Container>{children}</Container>
        </main>
      </Grid>
    </Grid>
  );
};

export default Layout;
