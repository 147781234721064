import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthState = {
  token: string | null;
};

const slice = createSlice({
  name: 'auth',
  initialState: { token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>
    ) => {
      state.token = token;
    },
    clearCredentials: (state) => {
      state.token = null;
    },
  },
});

export const { setCredentials, clearCredentials } = slice.actions;

export const selectLoggedIn = (state: RootState) => !!state.auth.token;

export default slice.reducer;
