import { Category } from '@app/components/ui/category-select/CategorySelect';
import { PlugTag } from '@app/services/types';

const plugCategories: Category<PlugTag>[] = [
  // **Comms / Network**
  {
    label: 'Signal strength (dB)',
    options: [{ value: 'rssi' }],
  },
  {
    label: 'Plug upload attempts',
    options: [{ value: 'upnn' }, { value: 'upbu' }],
  },
  // **Plug electrical measurements**
  {
    label: 'Plug electrical measurements',
    options: [
      { value: 'pbws' },
      { value: 'pbwa' },
      { value: 'pbc' },
      { value: 'pbpd' },
      { value: 'pbvo' },
    ],
  },
  // **Misc**
  {
    label: 'Misc',
    options: [
      { value: 'ptmp' },
      { value: 'blew' }
    ],
  },
];
export default plugCategories;
