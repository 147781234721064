import Panel from '@app/components/ui/Panel';
import { deviceTitle } from '@app/lib/devices';
import { DeviceField, DeviceSnapshot } from '@app/services/types';
import { CardContent, CardHeader } from '@material-ui/core';
import DeviceCategory from '../device-details/DeviceCategory';
import GoogleMapLink from './GoogleMapLink';

export type SensorDeviceCardProps = {
  device: DeviceSnapshot;
};

const categories: { title: string; fields: DeviceField[] }[] = [
  {
    title: 'Device',
    fields: ['did', 'datafmt', 'installed_on', 'last_factor_time', 'sats'],
  },
  {
    title: 'Installation',
    fields: ['installed_on', 'imag', 'ivol', 'irsi'],
  },
  {
    title: 'Firmware',
    fields: ['upis', 'upup', 'upau'],
  },
  {
    title: 'Signal strength',
    fields: ['rssi', 'average_rssi', 'rars', 'average_rars'],
  },
  {
    title: 'Bluetooth',
    fields: ['average_ble_lost_rate', 'average_ble_retry', 'rasi', 'rase'],
  },
  {
    title: 'Battery',
    fields: ['batt', 'last_battery_time'],
  },
  {
    title: 'Calibration',
    fields: [
      'snr',
      'pecorrectedavgsignal',
      'perwatt',
      'scaleuncertainty',
      'phaseuncertainty',
      'calibrationchange',
      'phasediscrepancy',
      'allplugsscore',
    ],
  },
];

const SensorDeviceCard = ({ device }: SensorDeviceCardProps) => {
  return (
    <Panel>
      <CardHeader title={`Sensor: ${deviceTitle(device)}`} />
      <CardContent>
        <GoogleMapLink
          lat={device.lat}
          lon={device.lon}
        />
      </CardContent>

      <CardContent>
        {categories.map(({ title, fields }) => (
          <DeviceCategory
            key={title}
            title={title}
            fields={fields}
            device={device}
          />
        ))}
      </CardContent>

    </Panel>
  );
};

export default SensorDeviceCard;
