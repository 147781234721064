import {
  SUPPORT_ACCOUNT_DETAILS_PATH,
  SUPPORT_API,
  SUPPORT_DEVICE_DIAGNOSTICS_HISTORY_PATH,
  SUPPORT_DEVICE_INSTALLATION_HISTORY_PATH,
} from '@app/config/api';
import { toAccountId } from '@app/lib/account-id';
import {
  Account,
  DeviceSnapshot,
  DeviceTimeSeries,
  Tag,
  TaggedTimeSeries,
} from '@app/services/types';
import { RootState } from '@app/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

type DataResponse<T> = {
  data: T;
};

type GetAccountDetailsRequest = {
  searchTerm: string;
  searchField: string;
};

export type GetAccountDetailsResponse = {
  account: Account;
  devices: DeviceSnapshot[];
};

type DeviceHistoryResponse = {
  data: DeviceTimeSeries[];
};

type TagHistoryResponse = {
  data: TaggedTimeSeries;
};

type DeviceTagHistoryRequest = {
  tag: Tag;
  deviceId: string;
  // TODO: wrapper for queries to allow non-serializable args
  // from: Date;
  // to: Date;
  from: string;
  to: string;
};

type ListAccountsRequest = {
  // TODO: wrapper for queries to allow non-serializable args
  // from: Date;
  // to: Date;
  from: string;
  to: string;
};

export const supportApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: SUPPORT_API,
    prepareHeaders(headers, { getState }) {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Device', 'DeviceTag'],
  endpoints: (builder) => ({
    getAccountDetails: builder.query<
      GetAccountDetailsResponse,
      GetAccountDetailsRequest
    >({
      query: ({ searchField, searchTerm }) => ({
        url: SUPPORT_ACCOUNT_DETAILS_PATH,
        params: {
          searchField,
          searchTerm:
            searchField === 'firebaseId' ? toAccountId(searchTerm) : searchTerm,
        },
      }),
      transformResponse({ data }: DataResponse<GetAccountDetailsResponse>) {
        return data;
      },
    }),
    getDeviceTagHistory: builder.query<
      TaggedTimeSeries,
      DeviceTagHistoryRequest
    >({
      query({ deviceId: device, tag, from, to }) {
        return {
          url: SUPPORT_DEVICE_DIAGNOSTICS_HISTORY_PATH,
          params: {
            device,
            tag,
            from,
            to,
          },
        };
      },
      transformResponse({ data }: TagHistoryResponse) {
        return data;
      },
    }),
    listRecentInstallations: builder.query<
      DeviceTimeSeries[],
      ListAccountsRequest
    >({
      query({ from, to }) {
        return {
          url: SUPPORT_DEVICE_INSTALLATION_HISTORY_PATH,
          params: { from, to },
        };
      },
      transformResponse({ data }: DeviceHistoryResponse) {
        return data;
      },
    }),
  }),
  reducerPath: 'supportApi',
});

export const {
  useGetAccountDetailsQuery,
  useGetDeviceTagHistoryQuery,
  useListRecentInstallationsQuery,
} = supportApi;
