import { DeviceField, Tag } from '@app/services/types';
import { GLOSSARY_CONTENT } from './content';

export type Concept = Tag | DeviceField;

export type GlossaryEntryBase = {
  name?: Concept;
  shortLabel?: string;
  label: string;
  summary: string;
};

export type GlossaryEntry = Required<GlossaryEntryBase> & { defined: boolean };

// Remove entries from this as you add them to the GLOSSARY_CONTENT in ./content.ts. When all of them are defined, then DefinedConcept just becomes Concept :)
type UndefinedConcept =
  | 'external_id'
  | 'name'
  | 'locale'
  | 'timezone'
  | 'lat'
  | 'lon'
  | 'rauc'
  | 'nwkc'
  | 'nwku'
  | 'nwku-nwkc'
  | 'rntm'
  | 'tsr1'
  | 'tsr2'
  | 'upld'
  | 'upct'
  | 'am00'
  | 'am01'
  | 'am02'
  | 'am03'
  | 'am04'
  | 'am05'
  | 'm00'
  | 'm01'
  | 'm02'
  | 'm03'
  | 'm04'
  | 'm05'
  | 'e00'
  | 'e01'
  | 'ptji'
  | 'sahp'
  | 'ktmp'
  | 'upcf'
  | 'cprr'
  | 'hwrv'
  | 'cbvo'
  | 'cbam'
  | 'cbwa'
  | 'ctvo'
  | 'ctan'
  | 'ctwa'
  | 'pbws'
  | 'pbwa'
  | 'pbc'
  | 'pbpd'
  | 'pbvo';

type DefinedConcept = Exclude<Concept, UndefinedConcept>;

export type Glossary<EntryType> = { [key in DefinedConcept]: EntryType };

const GLOSSARY = Object.fromEntries(
  Object.entries(GLOSSARY_CONTENT).map(
    ([concept, { shortLabel = concept.toUpperCase(), ...entry }]) => [
      concept,
      {
        ...entry,
        name: concept,
        shortLabel,
        defined: true,
      } as GlossaryEntry,
    ]
  )
) as Glossary<GlossaryEntry>;

const NOT_YET_DEFINED = 'This concept does not yet have a definition';

export const glossaryFor = (concept: Concept): GlossaryEntry =>
  GLOSSARY[concept as DefinedConcept]
    ? GLOSSARY[concept as DefinedConcept]
    : {
        name: concept,
        shortLabel: concept.toUpperCase(),
        label: concept.toUpperCase(),
        summary: NOT_YET_DEFINED,
        defined: false,
      };
