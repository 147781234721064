import { AUTH_API, AUTH_LOGIN_PATH } from '@app/config/api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export type LoginResponse = {
  token: string;
};

type BaseLoginResponse = {
  jwt: string;
};

export type LoginRequest = {
  username: string;
  password: string;
};

export const authApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: AUTH_API,
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: AUTH_LOGIN_PATH,
        method: 'POST',
        body: credentials,
      }),
      transformResponse: ({ jwt: token }: BaseLoginResponse) => {
        return { token };
      },
    }),
  }),
  reducerPath: 'authApi',
});

export const { useLoginMutation } = authApi;
