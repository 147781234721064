import { Glossary, GlossaryEntryBase } from './index';

export const firmware = {
  shortLabel: 'Firmware',
  label: 'Firmware version',
  summary: 'Currently installed firmware version. Integer build number.',
};

export const requestedFirmware = {
  shortLabel: 'Requested firmware',
  label: 'Requested firmware version',
  summary:
    'Requested firmware version for OTA firmware upgrade. Integer build number.',
};

export const battery = {
  shortLabel: 'Battery',
  label: 'Battery level',
  summary:
    'Floating point value, in volts. Range 3.0...4.2. Translates to percentage as 3.4V=0%, 4.1V=100%, linear in between.',
};

export const GLOSSARY_CONTENT: Glossary<GlossaryEntryBase> = {
  blew: {
    shortLabel: 'Watt',
    label: 'Wattage',
    summary:
    'The power value measured by the plug.',
  },
  did: {
    shortLabel: 'Device ID',
    label: 'Device ID',
    summary:
      'The MAC address of the device, assigned by the device manufacturer.',
  },
  datafmt: {
    shortLabel: 'Type',
    label: 'Device type',
    summary:
      'The type of a device, i.e. sensor (sensor6) or plug (calibrator1).',
  },
  installed_on: {
    shortLabel: 'Installed on',
    label: 'Installed on',
    summary: 'The date this device was installed.',
  },
  last_factor_time: {
    shortLabel: 'Last factor time',
    label: 'Last factor time',
    summary:
      'The date when calibration factors for a device were last calculated.',
  },
  average_ble_retry: {
    shortLabel: 'Av BLE retry',
    label: 'Average bluetooth retry',
    summary:
      'The average amount of times a device has had to retry making a BLE connection.',
  },
  average_ble_lost_rate: {
    shortLabel: 'Av BLE lost',
    label: 'Average bluetooth lost rate',
    summary: 'The average amount of bluetooth losses for a device.',
  },
  'ble-lost-rate%': {
    shortLabel: 'BLE lost rate',
    label: 'bluetooth lost rate',
    summary: 'The amount of bluetooth losses for a device.',
  },
  'ble-retry-avg': {
    shortLabel: 'BLE retry',
    label: 'bluetooth retry amount',
    summary: 'The amount of times a device has had to retry making a BLE connection.',
  },
  max_watt: {
    shortLabel: 'Max watt',
    label: 'Max wattage',
    summary:
      'The maximum power value calculated on a sensor. Negative for production (e.g solar systems).',
  },
  watt: {
    shortLabel: 'Watt',
    label: 'Wattage',
    summary:
      'The last power value calculated on a sensor. Negative for production (e.g solar systems).',
  },
  average_rars: {
    shortLabel: 'Av RARS',
    label: 'Average RARS',
    summary:
      'The average bluetooth signal strength in dB. -60 is very good, -100 is very weak.',
  },
  average_rssi: {
    shortLabel: 'Av RSSI',
    label: 'Average RSSI',
    summary:
      'The average WiFi signal strength in dB. -40 is excellent, -90 is poor.',
  },
  sats: {
    shortLabel: 'Uptime',
    label: 'Sensor uptime (seconds)',
    summary: 'Uptime of the sensor at the last sample in seconds.',
  },
  satp: {
    label: 'Sensor uptime (sub-second)',
    summary:
      'Uptime of the sensor at the last sample in increments of 1/32768th of a second.',
  },
  imag: {
    label: 'Magnetic field at install',
    summary:
      'Last reported magnetic field measurement during install. From 0 to 1024, with every 256 representing one LED worth.',
  },
  ivol: {
    label: 'Electric field at install',
    summary:
      'Last reported electric field measurement during install. From 0 to 1024, with every 256 representing one LED worth.',
  },
  irsi: {
    label: 'WiFi RSSI at install',
    summary:
      'Install time WiFi RSSI value in dB. -40 is excellent, -90 is poor.',
  },
  fw_version: firmware,
  upis: firmware,
  fw_version_wanted: requestedFirmware,
  upup: requestedFirmware,
  upau: {
    shortLabel: 'Upgrade status',
    label: 'Upgrade status',
    summary:
      'OTA upgrade attempt status. Non-zero values for error indication, 0 for success.',
  },
  rssi: {
    label: 'WiFi signal strength',
    summary: 'The WiFi signal strength in dB. -40 is excellent, -90 is poor.',
  },
  rars: {
    label: 'Bluetooth signal strength',
    summary:
      'The bluetooth signal strength in dB. -60 is very good, -100 is very weak.',
  },
  rasi: {
    label: 'Samples for transfer',
    summary: 'The number of samples on the device available for transfer.',
  },
  rase: {
    label: 'Sample transfer attempts',
    summary:
      'The number of attempts made to transfer available samples from the device.',
  },
  rasu: {
    label: 'Successful sample transfers',
    summary: 'The number of successful sample transfers from the device.',
  },
  rafs: {
    label: 'Diagnostics transfer attempts',
    summary:
      'Miscellaneous diagnostics transfer attempts. Diagnostic transfers are only attempted when we run out of power samples, and still have capacity on the BLE link.',
  },
  rafu: {
    label: 'Successful diagnostics transfers',
    summary:
      'The number of successful diagnostics transfers from the device. Diagnostic transfers are only attempted when we run out of power samples, and still have capacity on the BLE link.',
  },
  battery: battery,
  batt: battery,
  last_battery_time: {
    shortLabel: 'Last battery time',
    label: 'Last battery time',
    summary: 'The last time the battery was checked.',
  },
  uptm: {
    shortLabel: 'WiFi Uptime',
    label: 'Plug WiFi module uptime',
    summary: 'Uptime of the plug WiFi module at the last sample in seconds.',
  },
  init: {
    shortLabel: 'Init time',
    label: 'Initialisation time',
    summary: 'First post-boot S4PP connection time',
  },
  ptmp: {
    shortLabel: 'Temp',
    label: 'Plug temp',
    summary:
      'Temperature inside the plug in degrees Celsius. Warn if >55. Warn emphatically if >70.',
  },
  uppt: {
    shortLabel: 'Partition',
    label: 'Firmware partition',
    summary: 'Partition from which plug firmware runs.',
  },
  upts: {
    shortLabel: 'Build timestamp',
    label: 'Firmware build timestamp',
    summary: 'The timestamp of the build for currently running firmware.',
  },
  upbu: {
    shortLabel: 'Aborted uploads (WiFi)',
    label: 'Aborted upload count (WiFi)',
    summary:
      'Count of consecutive upload attempts which were aborted because the plug was not on a Wifi network.',
  },
  upnn: {
    shortLabel: 'Aborted uploads',
    label: 'Aborted upload count',
    summary:
      'Count of consecutive upload attempts which were aborted because an earlier upload was still active.',
  },
  failreason: {
    shortLabel: 'Fail reason',
    label: 'Calibration failure reason',
    summary: 'If calibration failed, the reason for the failure.',
  },
  wireresistance: {
    shortLabel: 'Wire resistance',
    label: 'Wire resistance',
    summary:
      'The inferred resistance of wiring between power source (i.e. grid) and the power point the plug is plugged into.',
  },
  maxchange: {
    shortLabel: 'Max change',
    label: 'Largest current change',
    summary:
      'The largest current change seen by the plug. Given in Ampere. Useful loads are at least 0.4A, OK ones at least 0.8A, and good ones at lest 4A.',
  },
  changecount: {
    shortLabel: 'Change count',
    label: 'Usable change pair count',
    summary:
      'How many before/after change pairs we can use. This is related to how many on/off events there were for the load, but is not the same - because we create multiple pairs across each event, to reduce the influence of noise.  <20 bad, <50 unfortunate, <125 OK, >125 good.',
  },
  snr: {
    shortLabel: 'SNR',
    label: 'Signal to noise ratio',
    summary:
      'Signal to noise/interference ratio. Indicating how "clean" the received signal is. <2 Bad, 2...5 unfortunate, 5...10 OK, >10 good.',
  },
  pecorrectedavgsignal: {
    shortLabel: 'Phase error',
    label: 'Phase error after correction',
    summary:
      'Same as perawsignal, but *after* correction. This is what would actually be be seen by the user. <0.01 good, 0.01...0.03 OK, 0.03...0.05 unfortunate, >0.05 Bad.',
  },
  perwatt: {
    shortLabel: 'Per watt',
    label: 'Magnetic signal per 1W',
    summary:
      'Indicates the magnetic signal generated by 1W of reported power. The higher the better. <1 bad, 1...4 unfortunate, 4...10 OK, >10 good.',
  },
  scaleuncertainty: {
    shortLabel: 'Scale uncertainty',
    label: 'Scale uncertainty',
    summary:
      'How uncertain we are about the "perwatt" value, as a fraction. <0.01 good, 0.01...0.03 OK, 0.03...0.05 unfortunate, >0.05 Bad.',
  },
  phaseuncertainty: {
    shortLabel: 'Phase uncertainty',
    label: 'Phase uncertainty',
    summary:
      'How uncertain we are about the phase of the measurement for this sensor. In degrees <1 good, 1...3 OK, 3...5 unfortunate, >5 Bad.',
  },
  calibrationchange: {
    shortLabel: 'Calibration change',
    label: 'Calibration change',
    summary:
      'How much the calibration has changed from the previous calibration for the same sensor. Reported roughly as a percentage, as the maximum of a number of individual "change" estimates. <1 good, 1...3 maybe notable, 3...5 notable, >5 concerning.',
  },
  phasediscrepancy: {
    shortLabel: 'Phase discrepancy',
    label: 'Phase discrepancy',
    summary:
      "The difference between the WoH sensor's phase as determined from the plug data and the phase as determined by matching the solar sensor's phase. <1 good, 1...2 OK, 2...5 unfortunate, >5 bad.",
  },
  allplugsscore: {
    shortLabel: 'Total change',
    label: 'Total change across all plugs',
    summary:
      'Total change observed across all the plugs in the system, used for determining the "perwatt" entry of the WoH sensor. <500 bad, 500...1000 unfortunate, 1000...2000 OK, >2000 good.',
  },
};
