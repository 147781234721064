import { tickFormatter } from '@app/lib/graph';
import { GraphAxis } from '@app/slices/graph';
import { VictoryAxis, VictoryAxisProps } from 'victory';

type DeviceGraphAxisProps = Omit<
  VictoryAxisProps,
  'tickValues' | 'tickFormat' | 'dependentAxis'
> & {
  axis: GraphAxis;
  axisUnitOffset: number;
};

const tickValues = [0.25, 0.5, 0.75, 1];

const DeviceGraphAxis = ({
  axis: {
    tag,
    minValue,
    maxValue,
    color,
    position: { first, last, index },
  },
  axisUnitOffset,
  ...props
}: DeviceGraphAxisProps) => {
  const tickFormat = tickFormatter({ minValue, maxValue });
  const lastNotFirst = last && !first;
  const offsetX = lastNotFirst
    ? 50
    : first
    ? undefined
    : index * axisUnitOffset;
  return (
    <VictoryAxis
      {...props}
      orientation={lastNotFirst ? 'right' : 'left'}
      offsetX={offsetX}
      dependentAxis
      // Use normalized tickValues (0 - 1)
      tickValues={tickValues}
      // Re-scale ticks by multiplying by correct maxima
      tickFormat={tickFormat}
      label={tag}
      style={{
        axis: { stroke: color },
        axisLabel: {
          padding: 0,
          fill: color,
        },
        tickLabels: {
          fill: color,
          textAnchor: lastNotFirst ? 'start' : 'end',
        },
      }}
    />
  );
};

export default DeviceGraphAxis;
