import { Category } from '@app/components/ui/category-select/CategorySelect';
import { SensorTag } from '@app/services/types';

const sensorCategories: Category<SensorTag>[] = [
  // **Comms**
  {
    label: 'Signal strength (dB)',
    options: [{ value: 'rssi' }, { value: 'rars' }],
  },
  {
    label: 'BLE Packets stats',
    options: [
      { value: 'rasi' },
      { value: 'rase' },
      { value: 'rasu' },
      { value: 'rafs' },
      { value: 'rafu' },
      { value: 'ble-lost-rate%' },
      { value: 'ble-retry-avg' },
    ],
  },
  {
    label: 'WiFi / network stats',
    options: [
      { value: 'nwkc' },
      { value: 'nwku' },
      { value: 'rntm' },
      { value: 'tsr1' },
      { value: 'tsr2' },
      { value: 'upld' },
      { value: 'upct' },
    ],
  },

  // **Sensing data**
  {
    label: 'Mag ave readings',
    options: [
      { value: 'am00' },
      { value: 'am01' },
      { value: 'am02' },
      { value: 'am03' },
      { value: 'am04' },
      { value: 'am05' },
    ],
  },
  {
    label: 'Mag raw readings',
    options: [
      { value: 'm00' },
      { value: 'm01' },
      { value: 'm02' },
      { value: 'm03' },
      { value: 'm04' },
      { value: 'm05' },
    ],
  },
  {
    label: 'E field readings',
    options: [{ value: 'e00' }, { value: 'e01' }],
  },
  {
    label: 'Phase tracking',
    options: [{ value: 'ptji' }, { value: 'sahp' }],
  },

  // **Misc**
  {
    label: 'Misc',
    options: [{ value: 'batt' }, { value: 'ktmp' }],
  },

  // **Sensor-power readings**
  {
    label: 'Sensor-power readings',
    options: [{ value: 'watt' }],
  },
];
export default sensorCategories;
