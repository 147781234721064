import AccountDetails from '@app/components/account/AccountDetails';
import Panel from '@app/components/ui/Panel';
import VerticalBaseline from '@app/components/ui/VerticalBaseline';
import useQuery from '@app/hooks/useQuery';
import { accountDetailsPath } from '@app/lib/account-id';
import { SearchField } from '@app/services/types';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AccountSearchField = styled(TextField)(({ theme }) => ({
  minWidth: theme.spacing(50),
}));

const AccountPage = () => {
  const query = useQuery();
  const searchField = query.get('searchField') as SearchField;
  const rawSearchField = query.get('searchTerm');

  const searchTerm = rawSearchField ? decodeURIComponent(rawSearchField) : '';

  let navigate = useNavigate();
  const [newSearchTerm, setNewSearchTerm] = useState(searchTerm);
  const [newSearchField, setNewSearchField] = useState(
    searchField || 'firebaseId'
  );

  const goToAccount = () =>
    navigate(accountDetailsPath(newSearchField, newSearchTerm));

  return (
    <Panel>
      <Typography variant="h6">Search</Typography>
      <Panel>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item style={{ minWidth: '10rem' }}>
            <FormControl fullWidth>
              <InputLabel>Search Field</InputLabel>
              <Select
                fullWidth
                labelId="Field"
                value={newSearchField}
                onChange={(e) =>
                  setNewSearchField(e.target.value as SearchField)
                }
              >
                <MenuItem value="firebaseId">Firebase ID</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <AccountSearchField
              label="Account ID"
              onChange={(e) => setNewSearchTerm(e.target.value)}
              defaultValue={searchTerm}
            />
          </Grid>
          <Grid item>
            <VerticalBaseline>
              <Button color="primary" variant="contained" onClick={goToAccount}>
                Go
              </Button>
            </VerticalBaseline>
          </Grid>
        </Grid>
      </Panel>

      {searchTerm && (
        <AccountDetails searchTerm={searchTerm} searchField={searchField} />
      )}
    </Panel>
  );
};

export default AccountPage;
