import { AddCircleIcon, EmailIcon, LockOpenIcon } from '@app/icons';
import { formatShortDateTime } from '@app/lib/dates';
import { Account } from '@app/services/types';
import {
  Avatar,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import Panel from '../ui/Panel';

type AccountUserProps = Account;

const AccountUser = ({
  photoURL,
  name,
  email,
  creationTime,
  lastSignInTime,
}: AccountUserProps) => (
  <Panel>
    <CardHeader
      avatar={<Avatar src={photoURL || ''} />}
      title={name || 'Powersensor User'}
    />
    <hr />
    <List component="ul" aria-label="main mailbox folders">
      <ListItem>
        <ListItemIcon title="Email">
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary={email} />
      </ListItem>
      <ListItem title="Created at">
        <ListItemIcon>
          <AddCircleIcon />
        </ListItemIcon>
        <ListItemText primary={formatShortDateTime(new Date(creationTime))} />
      </ListItem>
      <ListItem>
        <ListItemIcon title="Last login">
          <LockOpenIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            lastSignInTime ? formatShortDateTime(new Date(lastSignInTime)) : '-'
          }
        />
      </ListItem>
    </List>
  </Panel>
);

export default AccountUser;
