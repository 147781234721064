import { accountDetailsPath, fromAccountId } from '@app/lib/account-id';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

const AccountIdCell = ({ value }: GridRenderCellParams) => {
  const accountId = value as string;
  const accountLabel = fromAccountId(accountId);
  return <Link to={accountDetailsPath("firebaseId", accountId)}>{accountLabel}</Link>;
};
export default AccountIdCell;
