import { LOGOUT_PAGE } from '@app/config/titles';
import { useAppDispatch } from '@app/hooks';
import { clearCredentials } from '@app/slices/auth';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearCredentials());
    navigate('/');
  }, [navigate, dispatch]);

  return (
    <Helmet>
      <title>{LOGOUT_PAGE}</title>
    </Helmet>
  );
};

export default LoginPage;
