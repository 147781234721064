import AccountIdCell from '@app/components/installations/AccountIdCell';
import { formatShortDateTime } from '@app/lib/dates';
import { useListRecentInstallationsQuery } from '@app/services/support';
import {
  DataGrid,
  GridColDef,
  GridRowData,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useState } from 'react';

const numberFormatter = ({ value }: GridValueFormatterParams) => {
  return value == null ? '-' : (+value as number).toFixed(2);
};

const dataCellProps = {
  valueFormatter: numberFormatter,
  hide: true,
  flex: 1,
};

const columns: GridColDef[] = [
  {
    field: 'external_id',
    headerName: 'Account ID',
    flex: 1,
    renderCell: AccountIdCell,
  },
  {
    field: 'datetime',
    headerName: 'Installed On',
    flex: 1,
    valueFormatter: ({ value }: GridValueFormatterParams) => {
      return value ? formatShortDateTime(new Date(value as string)) : '';
    },
  },
  {
    field: 'did',
    headerName: 'Device ID',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Device name',
    flex: 1,
  },
  {
    field: 'datafmt',
    headerName: 'Data format',
    flex: 1,
  },
  {
    field: 'averageBleLostRate',
    headerName: 'Average BLE lost rate',
    ...dataCellProps,
  },
  {
    field: 'averageBleRetry',
    headerName: 'Average BLE retry',
    ...dataCellProps,
  },
  {
    field: 'averageRSSI',
    headerName: 'Average RSSI',
    ...dataCellProps,
  },
  {
    field: 'averageRars',
    headerName: 'Average RARS',
    ...dataCellProps,
  },
  {
    field: 'plugPeakWatts',
    headerName: 'Plug peak watts',
    ...dataCellProps,
  },
];

export type RecentInstallationsProps = {
  from: Date;
  to: Date;
};

const RecentInstallations = ({ from, to }: RecentInstallationsProps) => {
  const { data: rows, isFetching } = useListRecentInstallationsQuery(
    {
      from: from.toISOString(),
      to: to.toISOString(),
    },
    {
      selectFromResult({ data: rawData, ...queryResult }) {
        const data: readonly GridRowData[] =
          rawData?.map(({ did, ...row }) => ({
            id: did,
            did,
            ...row,
          })) ?? [];
        return { data, ...queryResult };
      },
    }
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <DataGrid
        loading={isFetching}
        rows={isFetching ? [] : rows}
        columns={columns}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[5, 10]}
        autoHeight
      />
    </div>
  );
};
export default RecentInstallations;
