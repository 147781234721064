import RecentInstallations from '@app/components/installations/RecentInstallations';
import DateTimeRangePicker from '@app/components/ui/DateTimeRangePicker';
import Panel from '@app/components/ui/Panel';
import { RECENT_INSTALLATIONS_PAGE } from '@app/config/titles';
import { Grid, Typography } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';

const RecentInstallationsPage = () => {
  const [[from, to], setRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const onRangeSelect = useCallback(
    (start: Date | null, end: Date | null) => setRange([start, end]),
    [setRange]
  );

  return (
    <>
      <Helmet>
        <title>{RECENT_INSTALLATIONS_PAGE}</title>
      </Helmet>
      <Panel>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h2" noWrap>
              Recent installations
            </Typography>
          </Grid>
          <Grid item>
            <DateTimeRangePicker onRangeSelect={onRangeSelect} />
          </Grid>
          <Grid item>
            {from && to && <RecentInstallations from={from} to={to} />}
          </Grid>
        </Grid>
      </Panel>
    </>
  );
};

export default RecentInstallationsPage;
