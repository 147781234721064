import { ExpandMoreIcon } from '@app/icons';
import { AccordionSummary, AccordionSummaryProps } from '@material-ui/core';

export type IconAccordionSummaryProps = AccordionSummaryProps;

const defaultIcon = <ExpandMoreIcon />;

const IconAccordionSummary = ({
  children,
  expandIcon = defaultIcon,
  ...props
}: IconAccordionSummaryProps) => (
  <AccordionSummary expandIcon={expandIcon} {...props}>
    {children}
  </AccordionSummary>
);

export default IconAccordionSummary;
