import Panel from '@app/components/ui/Panel';
import { deviceTitle } from '@app/lib/devices';
import { DeviceField, DeviceSnapshot } from '@app/services/types';
import { CardContent, CardHeader } from '@material-ui/core';
import DeviceCategory from '../device-details/DeviceCategory';
import GoogleMapLink from './GoogleMapLink';

export type PlugDeviceCardProps = {
  device: DeviceSnapshot;
};

const categories: { title: string; fields: DeviceField[] }[] = [
  {
    title: 'Device',
    fields: ['did', 'datafmt', 'installed_on', 'init', 'uptm', 'ptmp'],
  },
  {
    title: 'Firmware',
    fields: ['upis', 'uppt', 'upts'],
  },
  {
    title: 'Comms',
    fields: ['rssi', 'average_rssi', 'upnn', 'upbu'],
  },
  {
    title: 'Calibration',
    fields: ['wireresistance', 'maxchange', 'changecount', 'failreason'],
  },
];

const PlugDeviceCard = ({ device }: PlugDeviceCardProps) => {
  return (
    <Panel>
      <CardHeader title={`Plug: ${deviceTitle(device)}`} />
      <CardContent>
        <GoogleMapLink
          lat={device.lat}
          lon={device.lon}
        />
      </CardContent>
      <CardContent>
        {categories.map(({ title, fields }) => (
          <DeviceCategory
            key={title}
            title={title}
            fields={fields}
            device={device}
          />
        ))}

      </CardContent>
    </Panel>
  );
};

export default PlugDeviceCard;
