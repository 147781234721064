import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TagsState {
  value: TagStateEntry;
}

type TagStateEntry = {
  [key: string]: Array<string>;
};

const initialState: TagsState = {
  value: {},
};

type TagPayload = {
  accountId: string;
  tags: string[];
};

export const tagsSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setAccountTags: (
      state,
      { payload: { accountId, tags } }: PayloadAction<TagPayload>
    ) => {
      state.value = { ...state.value, ...{ [accountId]: tags } };
    },
  },
});

export const { setAccountTags } = tagsSlice.actions;

export default tagsSlice.reducer;
