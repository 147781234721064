import { APP_TITLE } from '@app/config/titles';
import { UserIcon } from '@app/icons';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import Navigation from './Navigation';
import Title from './Title';

const Header = () => {
  return (
    <AppBar position="sticky">
      <Toolbar>
        <Title>
          <Navigation />
          <Typography variant="h6" noWrap>
            {APP_TITLE}
          </Typography>
        </Title>
        <IconButton color="inherit" size="small">
          <UserIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
