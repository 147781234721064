import { authApi } from '@app/services/auth';
import { supportApi } from '@app/services/support';
import authReducer from '@app/slices/auth';
import graphReducer from '@app/slices/graph';
import tagsReducer from '@app/slices/tags';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    auth: authReducer,
    graph: graphReducer,
    tags: tagsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware, supportApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
