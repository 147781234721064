import { Concept, glossaryFor } from '@app/lib/glossary';
import { Tooltip, Typography } from '@material-ui/core';
import { ReactElement } from 'react';

export type ConceptLabelProps = {
  concept: Concept;
  children?: ReactElement;
};

const ConceptLabel = ({ concept, children }: ConceptLabelProps) => {
  const { summary, shortLabel, label } = glossaryFor(concept);
  const tooltipTitle = `${label}: ${summary}`;
  return (
    <Tooltip title={tooltipTitle}>
      {children ?? <Typography>{shortLabel}</Typography>}
    </Tooltip>
  );
};

export default ConceptLabel;
