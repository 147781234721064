import { useAppDispatch } from '@app/hooks';
import { createDataSet, valueNormaliser } from '@app/lib/graph';
import { useGetDeviceTagHistoryQuery } from '@app/services/support';
import {
  addGraphValuesForEntry,
  GraphSelectionEntry,
  removeDataSetRequested,
  removeGraphValuesForEntry,
  selectGraphAxesByTag,
  setDataSetRequested,
} from '@app/slices/graph';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { VictoryLine, VictoryLineProps } from 'victory';

type DeviceDataSetProps = VictoryLineProps & {
  entry: GraphSelectionEntry;
  from: Date;
  to: Date;
};

const DeviceDataSet = ({
  entry,
  containerComponent,
  from,
  to,
  ...props
}: DeviceDataSetProps) => {
  const { tag } = entry;
  const { data, isSuccess, isFetching, requestId } =
    useGetDeviceTagHistoryQuery(
      { from: from.toISOString(), to: to.toISOString(), ...entry },
      {
        selectFromResult: ({ data: { [tag]: values = [] } = {}, ...rest }) => ({
          data: createDataSet({
            values,
            meta: entry,
          }),
          ...rest,
        }),
      }
    );

  const axis = useSelector(selectGraphAxesByTag)[tag];

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (requestId) {
      dispatch(setDataSetRequested({ requestId, isFetching, params: entry }));
      return () => {
        dispatch(
          removeDataSetRequested({ requestId, isFetching, params: entry })
        );
      };
    }
  }, [requestId, isFetching, entry, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        addGraphValuesForEntry({ entry, values: data.map(({ y }) => y) })
      );
      return () => {
        dispatch(removeGraphValuesForEntry({ entry }));
      };
    }
    // FIXME: use exhaustive deps. Right now it causes infinite render loop.
    // Caused by always adding, not replacing graph data for axis calcs.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, isSuccess, dispatch, entry, from, to]);

  if (isSuccess && axis) {
    const normaliseValue = valueNormaliser(axis);
    return (
      <VictoryLine
        {...props}
        data={data}
        y={(datum) => normaliseValue(datum.y)}
        containerComponent={containerComponent}
        style={{ data: { stroke: axis.color } }}
      />
    );
  }
  return null;
};

export default DeviceDataSet;
