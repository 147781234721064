import { colors } from '@material-ui/core';

const {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} = colors;

const graphColors = [
  blue,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  blueGrey,
  amber,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
].map((color) => color[500]);

export default graphColors;
