import DeviceList from '@app/components/device-details/DeviceList';
import DeviceGraphContainer from '@app/components/graphs/DeviceGraphContainer';
import Loading from '@app/components/ui/Loading';
import { useGetAccountDetailsQuery } from '@app/services/support';
import { Typography } from '@material-ui/core';
import AccountTags from './AccountTags';
import AccountUser from './AccountUser';

type AccountDetailsProps = {
  searchTerm: string;
  searchField: string;
};

const AccountDetails = ({ searchTerm, searchField }: AccountDetailsProps) => {
  const { data: accountDetails, isFetching } = useGetAccountDetailsQuery({
    searchTerm,
    searchField,
  });

  return (
    <Loading loading={isFetching} height="10rem" width="100%">
      {/* The only reason to have this complexity is for non-firebase accounts */}
      {accountDetails?.account || accountDetails?.devices?.length ? (
        <>
          <Typography variant="h6">Account</Typography>
          {accountDetails.account && (
            <AccountUser {...accountDetails.account} />
          )}
          <Typography variant="h6">Tags</Typography>
          <AccountTags accountId={accountDetails.account.id} />
          <Typography variant="h6">Devices</Typography>
          <DeviceList devices={accountDetails.devices ?? []} />
          <Typography variant="h6">Graph</Typography>
          <DeviceGraphContainer
            accountId={searchTerm}
            devices={accountDetails.devices || []}
          />
        </>
      ) : (
        <Typography variant="h6">
          No data found for field '{searchField}' with value '{searchTerm}'
        </Typography>
      )}
    </Loading>
  );
};

export default AccountDetails;
