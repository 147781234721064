import { TagValue } from '@app/services/types';
import { GraphAxis, GraphSelectionEntry } from '@app/slices/graph';

type MinMax = {
  minValue: number;
  maxValue: number;
};

const AXIS_PRECISION = 5;

export const tickFormatter =
  ({ minValue, maxValue }: MinMax) =>
  (tick: number) =>
    (Math.abs(maxValue - minValue) * tick + minValue).toPrecision(
      AXIS_PRECISION
    );

const alwaysOne = () => 1;
export const valueNormaliser = ({ minValue, maxValue }: MinMax) => {
  if (maxValue === minValue) {
    return alwaysOne;
  }
  return (value: number) => (value - minValue) / Math.abs(maxValue - minValue);
};

type DataSetInput = { meta: GraphSelectionEntry; values: TagValue[] };
export const createDataSet = ({ values, meta }: DataSetInput) =>
  values.map(({ value, startTime }) => ({
    x: new Date(startTime),
    y: +[value],
    meta,
  }));

export const GRAPH_WIDTH = 1140;

export const calculateAxisOffsetUnit = (axes: GraphAxis[]) =>
  GRAPH_WIDTH / (axes.length - 1);
