import { Link } from "@material-ui/core";

type GoogleMapLinkProps = {
    lat: number;
    lon: number;
}

const GoogleMapLink = ({ lat, lon }: GoogleMapLinkProps) => {
    const hrefLink = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lon}`;

    return (
        <Link href={hrefLink} underline="always">See in Google Maps</Link>
    )
}

export default GoogleMapLink;
