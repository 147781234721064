import DateTimeRangePicker from '@app/components/ui/DateTimeRangePicker';
import { useAppDispatch } from '@app/hooks';
import { DeviceSnapshot, Tag } from '@app/services/types';
import {
  clearGraph,
  selectGraphSelections,
  setGraphSelections,
} from '@app/slices/graph';
import { Button, Grid } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DeviceGraph from './DeviceGraph';
import DeviceTagMenu from './DeviceTagMenu';

export type DeviceGraphContainerProps = {
  accountId: string;
  devices: DeviceSnapshot[];
};

type TagsByDeviceId = {
  [deviceId: string]: Tag[];
};

const DeviceGraphContainer = ({
  accountId,
  devices,
}: DeviceGraphContainerProps) => {
  const [selectedDeviceTags, setSelectedDeviceTags] = useState<TagsByDeviceId>(
    {}
  );

  const dispatch = useAppDispatch();

  const handleDeviceTagSelect = (device: DeviceSnapshot) => (tags: Tag[]) =>
    setSelectedDeviceTags({ ...selectedDeviceTags, [device.did]: tags });

  const clearAllDeviceTags = useCallback(() => {
    setSelectedDeviceTags({});
    dispatch(clearGraph());
  }, [dispatch]);

  const plotCurrentlySelected = () =>
    dispatch(setGraphSelections([{ accountId, devices: selectedDeviceTags }]));

  const [[from, to], setRange] = useState<[Date?, Date?]>([
    undefined,
    undefined,
  ]);

  const onRangeSelect = useCallback(
    (start: Date | null, end: Date | null) => {
      if (!start || !end) return;
      setRange([start, end])
    }, [setRange]
  );

  const graphSelections = useSelector(selectGraphSelections);
  useEffect(() => {
    return () => {
      clearAllDeviceTags();
    };
  }, [clearAllDeviceTags]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container direction="row" spacing={2}>
          {devices.map((device) => (
            <Grid item key={device.did}>
              <DeviceTagMenu
                key={device.did}
                device={device}
                selectedTags={selectedDeviceTags[device.did]}
                handleTagSelect={handleDeviceTagSelect(device)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
          <Grid item>
            <DateTimeRangePicker onRangeSelect={onRangeSelect} />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={plotCurrentlySelected}
            >
              Plot tags
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="outlined"
              onClick={clearAllDeviceTags}
            >
              Clear all
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {graphSelections.length > 0 && from && to && (
          <DeviceGraph from={from} to={to} />
        )}
      </Grid>
    </Grid>
  );
};

export default DeviceGraphContainer;
