import LoadingButton from '@app/components/ui/LoadingButton';
import { useAppDispatch } from '@app/hooks';
import { useLoginMutation } from '@app/services/auth';
import { setCredentials } from '@app/slices/auth';
import { Card, Grid, styled, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Panel = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      const loginResponse = await login({ username, password }).unwrap();
      enqueueSnackbar('Login succeeded.', {
        variant: 'success',
      });
      dispatch(setCredentials(loginResponse));
      navigate('/');
    } catch (error) {
      enqueueSnackbar('Login failed. Check your username and password.', {
        variant: 'error',
      });
    }
  };

  return (
    <Panel>
      <form onSubmit={onSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              data-testid="username"
              label="Username"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              data-testid="password"
              label="Password"
              type="password"
              name="username"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              loading={isLoading}
            >
              Login
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Panel>
  );
};

export default Login;
