import Skeleton, { SkeletonProps } from '@material-ui/lab/Skeleton';
import { WithChildren } from '../types';

export type LoadingProps = WithChildren<
  Omit<SkeletonProps, 'role'> & { loading: boolean }
>;

const Loading = ({ loading, children, ...skeletonProps }: LoadingProps) => (
  <>
    {loading ? (
      <Skeleton aria-busy role="progressbar" {...skeletonProps}>
        {children}
      </Skeleton>
    ) : (
      children
    )}
  </>
);

export default Loading;
