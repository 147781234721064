import { selectDataSetsRequested } from '@app/slices/graph';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import DataSetLegendEntry from './DataSetLegendEntry';

export type DataSetLegendProps = {};

const DataSetLegend = (_props: DataSetLegendProps) => {
  const requestedDataSets = useSelector(selectDataSetsRequested);
  return (
    <Grid container direction="row" spacing={1}>
      {requestedDataSets.map((requestedDataSet) => (
        <Grid item key={requestedDataSet.requestId}>
          <DataSetLegendEntry requestedDataSet={requestedDataSet} />
        </Grid>
      ))}
    </Grid>
  );
};

export default DataSetLegend;
