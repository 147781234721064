import { APP_VERSION } from '@app/config/env';
import { MenuIcon, MenuOpenIcon } from '@app/icons';
import { Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import { MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="open menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        size="small"
      >
        {open ? <MenuOpenIcon /> : <MenuIcon />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to="/">
          Recent installations
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/account/">
          Account details
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/logout">
          Logout
        </MenuItem>
        <Divider />
        <MenuItem disabled>version: {APP_VERSION}</MenuItem>
      </Menu>
    </div>
  );
};

export default Navigation;
