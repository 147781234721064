import { formatShortDateTime } from '@app/lib/dates';
import { calculateAxisOffsetUnit, GRAPH_WIDTH } from '@app/lib/graph';
import {
  GraphSelectionEntry,
  selectGraphAxes,
  selectGraphSelectionEntries,
} from '@app/slices/graph';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  VictoryAxis,
  VictoryChart,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import AxisLegend from './AxisLegend';
import DataSetLegend from './DataSetLegend';
import DeviceDataSet from './DeviceDataSet';
import DeviceGraphAxis from './DeviceGraphAxis';

const selectionEntryKey = ({ accountId, deviceId, tag }: GraphSelectionEntry) =>
  `${accountId}-${deviceId}-${tag}`;

export type DeviceGraphProps = {
  from: Date;
  to: Date;
};

const DeviceGraph = ({ from, to }: DeviceGraphProps) => {
  const axes = useSelector(selectGraphAxes);
  const axisUnitOffset = calculateAxisOffsetUnit(axes);
  const selectionEntries = useSelector(selectGraphSelectionEntries);

  const containerComponent = (
    <VictoryVoronoiContainer
      labels={({
        datum: {
          x: date,
          y: value,
          meta: { tag, deviceId },
        },
      }) => {
        return `${tag} (${deviceId}): ${formatShortDateTime(date)}: ${value}`;
      }}
      labelComponent={<VictoryTooltip dy={-7} constrainToVisibleArea />}
      voronoiDimension="x"
      mouseFollowTooltips
    />
  );
  return (
    <Grid container direction="column">
      <Grid item>
        <DataSetLegend />
      </Grid>
      <Grid item>
        <VictoryChart
          domain={{
            y: [0, 1],
            x: [from, to],
          }}
          scale={{ x: 'time' }}
          theme={VictoryTheme.material}
          width={GRAPH_WIDTH}
          height={600}
          containerComponent={containerComponent}
        >
          <VictoryAxis />
          {axes.map((axis) => (
            <DeviceGraphAxis
              standalone={false}
              axisUnitOffset={axisUnitOffset}
              axis={axis}
              key={`${axis.tag}-axis`}
            />
          ))}
          {selectionEntries.map((entry) => (
            <DeviceDataSet
              standalone={false}
              containerComponent={containerComponent}
              key={selectionEntryKey(entry)}
              entry={entry}
              from={from}
              to={to}
            />
          ))}
        </VictoryChart>
      </Grid>
      <Grid item>
        <AxisLegend axes={axes} />
      </Grid>
    </Grid>
  );
};

export default DeviceGraph;
