import { useAuth } from '@app/hooks';
import { Navigate } from 'react-router-dom';
import { WithChildren } from '../types';

export type RequireAuthProps = WithChildren<{}>;

const LOGIN_PAGE = '/login';
const RequireAuth = ({ children }: RequireAuthProps): JSX.Element => {
  const { loggedIn } = useAuth();
  return loggedIn ? <>{children}</> : <Navigate to={LOGIN_PAGE} />;
};

export default RequireAuth;
