import { Button, Grid, MenuItem, styled, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { KeyboardDateTimePicker as BaseDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useEffect, useState } from 'react';
import {
  endOfToday,
  startOfLast7,
  startOfToday,
  startOfYesterday,
} from '../../lib/dates';

const RANGE_NAMES = ['today', 'yesterday', 'last7', 'custom'] as const;
const DATE_FORMAT = "dd/MM/yyyy HH:mm:ss"

export type RangeName = typeof RANGE_NAMES[number];

export type Range = [start: Date, end: Date];

export type DateTimeRangePickerProps = {
  onRangeSelect: (start: Date | null, end: Date | null) => void;
  defaultRange?: RangeName;
  helperText?: string;
  label?: string;
};

const DateRangeField = styled(TextField)(({ theme }) => ({
  minWidth: theme.spacing(25),
}));

const DateTimePicker = styled(BaseDateTimePicker)(({ theme }) => ({
  minWidth: theme.spacing(25),
}));

const RANGES: Record<RangeName, { rangeLabel: string; values: () => Range }> = {
  today: { rangeLabel: 'Today', values: () => [startOfToday(), endOfToday()] },
  yesterday: {
    rangeLabel: 'Yesterday',
    values: () => [startOfYesterday(), startOfToday()],
  },
  last7: {
    rangeLabel: 'Last 7 days',
    values: () => [startOfLast7(), endOfToday()],
  },
  custom: {
    rangeLabel: 'Custom',
    values: () => [startOfToday(), endOfToday()],
  },
};

const DateTimeRangePicker = ({
  defaultRange = 'today',
  onRangeSelect,
  label = 'Date range',
  helperText,
}: DateTimeRangePickerProps) => {
  const [defaultStart, defaultEnd] = RANGES[defaultRange].values();

  const [range, setRange] = useState<RangeName>(defaultRange);

  const [start, setStart] = useState<MaterialUiPickersDate>(defaultStart);
  const [end, setEnd] = useState<MaterialUiPickersDate>(defaultEnd);
  const [valid, setValid] = useState(true)

  useEffect(() => {
    const [rangeStart, rangeEnd] = RANGES[range].values();
    setStart(rangeStart);
    setEnd(rangeEnd);
  }, [range]);


  useEffect(() => {
    onRangeSelect(start, end);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValid(validDateRange(start, end));
  }, [start, end]);

  const validDateRange = (
    startDate: MaterialUiPickersDate, endDate: MaterialUiPickersDate) => {
    if (!startDate || !endDate) return false;
    return startDate < endDate;
  };

  return (
    <Grid container direction="row" alignItems="flex-end" spacing={2}>
      <Grid item>
        <DateRangeField
          select
          label={label}
          defaultValue={defaultRange}
          onChange={(e) => setRange(e.target.value as RangeName)}
          helperText={helperText}
        >
          {Object.entries(RANGES).map(([name, { rangeLabel }]) => (
            <MenuItem key={name} value={name}>
              {rangeLabel}
            </MenuItem>
          ))}
        </DateRangeField>
      </Grid>
      {range === 'custom' && (
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <DateTimePicker
                value={start}
                onChange={setStart}
                label="Starting"
                showTodayButton
                style={{ minWidth: '200px' }}
                name="start"
                format={DATE_FORMAT}
              />
            </Grid>
            <Grid item>
              <DateTimePicker
                value={end}
                onChange={setEnd}
                label="Ending"
                showTodayButton
                name="end"
                format={DATE_FORMAT}
              />
            </Grid>
          </Grid>
          {!valid && (
            <Alert severity="warning">Starting Date must be before Ending Date</Alert>
          )}
        </Grid>

      )}
      <Grid item>
        <Button disabled={!valid} color="primary" variant="contained" onClick={() => onRangeSelect(start, end)}>
          Go
        </Button>
      </Grid>
    </Grid>
  );
};

export default DateTimeRangePicker;
