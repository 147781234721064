import { GraphAxis } from '@app/slices/graph';
import { VictoryLegend, VictoryLegendProps } from 'victory';

type AxisLegendProps = VictoryLegendProps & {
  axes: GraphAxis[];
};

const AxisLegend = ({ axes, ...props }: AxisLegendProps) => {
  return (
    <VictoryLegend
      title="Tags"
      orientation="horizontal"
      gutter={20}
      style={{ border: { stroke: 'black' } }}
      data={axes.map(({ tag, color }) => ({
        name: tag,
        symbol: { fill: color },
      }))}
      {...props}
    />
  );
};
export default AxisLegend;
