import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

export type LoadingButtonProps = ButtonProps & { loading: boolean };

const LoadingButton = ({ loading, children, ...props }: LoadingButtonProps) => (
  <Button {...props}>
    {loading ? <CircularProgress color="inherit" size={26} /> : children}
  </Button>
);

export default LoadingButton;
