import { Box, styled } from '@material-ui/core';

const VerticalBaseline = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
}));

export default VerticalBaseline;
