import { TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import uniq from 'lodash/fp/uniq';

import { RootState } from '@app/store';
import Panel from '@app/components/ui/Panel';
import { setAccountTags } from '@app/slices/tags';
import { Autocomplete } from '@material-ui/lab';

type AccountTagsProps = {
  accountId: string;
};

const AccountTags = ({ accountId }: AccountTagsProps) => {
  const tags = useSelector(
    (state: RootState) => state.tags.value[accountId] || []
  );

  const allTags = useSelector(
    (state: RootState) =>
      uniq(
        Object.keys(state.tags.value)
          .map((key) => state.tags.value[key])
          .flat()
      ) || []
  );

  const dispatch = useDispatch();

  return (
    <Panel data-testid="tags-panel">
      <Autocomplete
        multiple
        freeSolo
        options={allTags}
        value={tags}
        onChange={(_event, value) => {
          dispatch(setAccountTags({ accountId, tags: value }));
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder="Enter a tag..." />
        )}
      />
    </Panel>
  );
};

export default AccountTags;
