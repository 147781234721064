import { styled } from '@material-ui/core';

const Title = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export default Title;
