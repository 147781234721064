import { Checkbox, ListSubheader, Select } from '@material-ui/core';
import InlineMenuItem from '../InlineMenuItem';
import CategorySelectFormControl from './CategorySelectFormControl';
import CategorySelectInput from './CategorySelectInput';
import CategorySelectInputLabel from './CategorySelectInputLabel';
import SelectedChipList from './SelectedChipList';
import { BaseValueType } from './types';

export type Option<ValueType> = {
  label?: string;
  value: ValueType;
};

export type Category<ValueType extends BaseValueType = BaseValueType> = {
  label: string;
  options: Option<ValueType>[];
};

export type CategorySelectProps<
  ValueType extends BaseValueType = BaseValueType
> = {
  label: string;
  categories: Category<ValueType>[];
  selected: ValueType[];
  handleSelection: (values: ValueType[]) => void;
};

const createOptionItems = <ValueType extends BaseValueType = BaseValueType>(
  options: Option<ValueType>[],
  selected: ValueType[]
) =>
  options.map(({ value, label: optionLabel = value }) => (
    <InlineMenuItem key={value} value={value}>
      <Checkbox checked={selected.includes(value)} value={value} />
      {optionLabel}
    </InlineMenuItem>
  ));

const createCategoryItems = <ValueType extends BaseValueType = BaseValueType>(
  categories: Category<ValueType>[],
  selected: ValueType[]
) =>
  categories.flatMap(({ label: categoryLabel, options }) => [
    <ListSubheader disableSticky key={categoryLabel}>
      {categoryLabel}
    </ListSubheader>,
    createOptionItems(options, selected),
  ]);

const CategorySelect = <ValueType extends BaseValueType = BaseValueType>({
  label,
  categories,
  selected,
  handleSelection,
}: CategorySelectProps<ValueType>) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleSelection(event.target.value as ValueType[]);
  };

  return (
    <CategorySelectFormControl>
      <CategorySelectInputLabel>{label}</CategorySelectInputLabel>
      <Select
        multiple
        fullWidth
        value={selected}
        onChange={handleChange}
        input={<CategorySelectInput />}
        renderValue={(selectedValue) => (
          <SelectedChipList selectedValues={selectedValue as ValueType[]} />
        )}
      >
        {createCategoryItems(categories, selected)}
      </Select>
    </CategorySelectFormControl>
  );
};

export default CategorySelect;
