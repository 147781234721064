import { DeviceField, DeviceSnapshot } from '@app/services/types';
import { Accordion, AccordionDetails, Typography } from '@material-ui/core';
import IconAccordionSummary from '../ui/IconAccordionSummary';
import DeviceFieldTable from './DeviceFieldTable';

export type DeviceCategoryProps = {
  device: DeviceSnapshot;
  fields: DeviceField[];
  title: string;
};

const DeviceCategory = ({ device, fields, title }: DeviceCategoryProps) => (
  <Accordion>
    <IconAccordionSummary>
      <Typography>{title}</Typography>
    </IconAccordionSummary>
    <AccordionDetails>
      <DeviceFieldTable device={device} fields={fields} />
    </AccordionDetails>
  </Accordion>
);

export default DeviceCategory;
