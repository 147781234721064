import Login from '@app/components/auth/Login';
import { LOGIN_PAGE } from '@app/config/titles';
import { Dialog, Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';

const LoginPage = () => {
  return (
    <>
      <Helmet>
        <title>{LOGIN_PAGE}</title>
      </Helmet>
      <Grid
        container
        direction="column"
        alignContent="center"
        justifyContent="space-around"
      >
        <Grid item>
          <Dialog open={true}>
            <Login />
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginPage;
