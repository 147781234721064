import { isSensor } from '@app/lib/devices';
import { DeviceSnapshot } from '@app/services/types';
import PlugDeviceCard from './PlugDeviceCard';
import SensorDeviceCard from './SensorDeviceCard';

export type DeviceCardProps = {
  device: DeviceSnapshot;
};

const DeviceCard = ({ device }: DeviceCardProps) =>
  isSensor(device) ? (
    <SensorDeviceCard device={device} />
  ) : (
    <PlugDeviceCard device={device} />
  );

export default DeviceCard;
