import { SearchField } from "@app/services/types";

const FIREBASE_PREFIX = 'firebase://';
const GOOGLE_PREFIX = 'google://';

const PREFIXES = [FIREBASE_PREFIX, GOOGLE_PREFIX];

export const toAccountId = (accountId: string) =>
  PREFIXES.some((prefix) => accountId.startsWith(prefix))
    ? accountId
    : `${FIREBASE_PREFIX}${accountId}`;

export const fromAccountId = (accountId: string) =>
  PREFIXES.reduce((value, prefix) => value.replace(prefix, ''), accountId);

export const accountDetailsPath = (
  searchField: SearchField,
  searchTerm: string
) => {
  const params = new URLSearchParams({ searchField, searchTerm });
  return `/account?${params.toString()}`;
};
