import CategorySelect from '@app/components/ui/category-select/CategorySelect';
import { getTagCategories } from '@app/lib/tag-categories';
import { DeviceSnapshot, Tag } from '@app/services/types';

export type DeviceTagMenuProps = {
  device: DeviceSnapshot;
  selectedTags?: Tag[];
  handleTagSelect: (tags: Tag[]) => void;
};

const DeviceTagMenu = ({
  device,
  selectedTags = [],
  handleTagSelect,
}: DeviceTagMenuProps) => {
  return (
    <CategorySelect
      label={device.name}
      categories={getTagCategories(device)}
      selected={selectedTags}
      handleSelection={handleTagSelect}
    />
  );
};

export default DeviceTagMenu;
