import { formatValueForDevice } from '@app/lib/formatters';
import { DeviceField, DeviceSnapshot } from '@app/services/types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import ConceptLabel from '../ui/ConceptLabel';

export type DeviceFieldTableProps = {
  device: DeviceSnapshot;
  fields: DeviceField[];
};

const DeviceFieldTable = ({ device, fields }: DeviceFieldTableProps) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          {fields.map((field) => (
            <TableCell key={field} align="right">
              <ConceptLabel concept={field} />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {fields.map((field) => (
            <TableCell key={field} align="right">
              {formatValueForDevice({ device, field })}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export default DeviceFieldTable;
