import { DeviceSnapshot } from '@app/services/types';
import DeviceCard from './DeviceCard';

export type DeviceListProps = {
  devices: DeviceSnapshot[];
};

const DeviceList = ({ devices }: DeviceListProps) => (
  <>
    {devices.map((device) => (
      <DeviceCard key={device.did} device={device} />
    ))}
  </>
);

export default DeviceList;
