import graphColors from '@app/lib/graph-colors';
import { Tag, TaggedTimeSeries } from '@app/services/types';
import { RootState } from '@app/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import isUndefined from 'lodash/fp/isUndefined';
import max from 'lodash/fp/max';
import min from 'lodash/fp/min';
import set from 'lodash/fp/set';
import unset from 'lodash/fp/unset';

export type GraphSelection = {
  accountId: string;
  devices: {
    [did: string]: Tag[];
  };
};

export type GraphSelectionEntry = {
  accountId: string;
  deviceId: string;
  tag: Tag;
};

export type GraphDataEntry = {
  tags: Tag[];
  values: TaggedTimeSeries;
};

export type DataPoint = {
  x: Date;
  y: number;
};

export type DataSetRequested = {
  params: GraphSelectionEntry;
  isFetching: boolean;
  requestId: string;
};

export type GraphState = {
  selections: GraphSelection[];
  valuesByEntry: {
    [tag in Tag]?: {
      [accountId: string]: {
        [deviceId: string]: number[];
      };
    };
  };
  dataSetsRequested: {
    [requestId: string]: DataSetRequested;
  };
};

export type GraphAxis = {
  minValue: number;
  maxValue: number;
  tag: Tag;
  color: string;
  position: {
    first: boolean;
    last: boolean;
    index: number;
  };
};

export type GraphAxesByTag = {
  [k in Tag]?: GraphAxis;
};

const initialState: GraphState = {
  selections: [],
  valuesByEntry: {},
  dataSetsRequested: {},
};

const slice = createSlice({
  name: 'graph',
  initialState,
  reducers: {
    setDataSetRequested: (
      state,
      {
        payload: { params: entry, requestId, isFetching },
      }: PayloadAction<DataSetRequested>
    ) => {
      state.dataSetsRequested[requestId] = {
        params: entry,
        requestId,
        isFetching,
      };
    },
    removeDataSetRequested: (
      state,
      { payload: { requestId } }: PayloadAction<DataSetRequested>
    ) => {
      delete state.dataSetsRequested[requestId];
    },
    addGraphValuesForEntry: (
      state,
      {
        payload: {
          entry: { tag, accountId, deviceId },
          values,
        },
      }: PayloadAction<{ entry: GraphSelectionEntry; values: number[] }>
    ) =>
      set(
        ['valuesByEntry', tag, accountId, deviceId],
        [min(values), max(values)],
        state
      ),
    removeGraphValuesForEntry: (
      state,
      {
        payload: {
          entry: { tag, accountId, deviceId },
        },
      }: PayloadAction<{ entry: GraphSelectionEntry }>
    ) => unset(['valuesByEntry', tag, accountId, deviceId], state),
    setGraphSelections: (
      state,
      { payload }: PayloadAction<GraphSelection[]>
    ) => {
      state.selections = payload;
    },
    clearGraph: () => {
      return initialState;
    },
  },
});

export const {
  setDataSetRequested,
  removeDataSetRequested,
  addGraphValuesForEntry,
  removeGraphValuesForEntry,
  setGraphSelections,
  clearGraph,
} = slice.actions;

export const selectGraphSelections = (state: RootState) =>
  state.graph.selections;

const selectValuesByEntry = (state: RootState) => state.graph.valuesByEntry;

const filterEmpty = ({ minValue }: Partial<GraphAxis>) =>
  !isUndefined(minValue);

export const selectValuesByTag = createSelector(
  selectValuesByEntry,
  (valuesByEntry) => {
    return Object.fromEntries(
      Object.entries(valuesByEntry).map(([tag, accountValues]) => [
        tag,
        Object.values(accountValues)
          .map((deviceValues) => Object.values(deviceValues))
          .flat(2),
      ])
    );
  }
);

export const selectGraphAxes = createSelector(
  selectValuesByTag,
  (valuesByTag) => {
    const nonEmpty = Object.entries(valuesByTag)
      .map(([tag, values]) => {
        return {
          tag: tag as Tag,
          minValue: min(values),
          maxValue: max(values),
        };
      })
      .filter(filterEmpty);

    const lastIndex = Object.keys(nonEmpty).length - 1;
    return nonEmpty.map((axis, index) => ({
      color: graphColors[index],
      position: {
        index,
        first: index === 0,
        last: index === lastIndex,
      },
      ...axis,
    })) as GraphAxis[];
  }
);

export const selectGraphAxesByTag = createSelector(selectGraphAxes, (axes) => {
  return axes.reduce(
    (acc, axis) => ({ ...acc, [axis.tag]: axis }),
    {}
  ) as GraphAxesByTag;
});

export const selectGraphSelectionEntries = createSelector(
  selectGraphSelections,
  (selections) =>
    selections.flatMap(({ accountId, devices }) =>
      Object.entries(devices).flatMap(([deviceId, tags]) =>
        tags.map((tag) => ({ accountId, deviceId, tag }))
      )
    )
);

const selectRawDataSetsRequested = (state: RootState) =>
  state.graph.dataSetsRequested;

export const selectDataSetsRequested = createSelector(
  selectRawDataSetsRequested,
  (entries) => Object.values(entries)
);

export default slice.reducer;
