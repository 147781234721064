import {
  DeviceField,
  DeviceSnapshot,
  Tag,
  TagValue,
} from '@app/services/types';
import { formatDuration, intervalToDuration } from 'date-fns/fp';
import { formatShortDateTime } from './dates';

export type FormattableField = Tag | DeviceField;

const isTagValue = (value: string | number | TagValue): value is TagValue => {
  return (value as TagValue)?.value !== undefined;
};

type FormatParams = {
  device: DeviceSnapshot;
  field: FormattableField;
};

type FieldValue = string | number | [string | number];

const EMPTY_VALUE_FORMAT = '-';

type FormattedValue = string | number;

type Formatter = (parameters: FormatParams) => FormattedValue;

type ValueHandler<InputType> = (value: InputType) => FormattedValue;
type FieldValueHandler = ValueHandler<FieldValue>;
type NumericFieldValueHandler = ValueHandler<number>;
type TagValueValueHandler = ValueHandler<TagValue>;

const fieldValue = ({ device, field }: FormatParams): FieldValue => {
  const value = device[field];
  if (isTagValue(value)) {
    return value.value;
  }
  return value;
};

const withFieldValue = (params: FormatParams, handler: FieldValueHandler) => {
  const value = fieldValue(params);
  if (null == value) {
    return EMPTY_VALUE_FORMAT;
  }
  return handler(value);
};

const withTagValue = (
  { device, field }: FormatParams,
  handler: TagValueValueHandler
) => {
  const tagValue = device[field];
  if (null == tagValue) {
    return EMPTY_VALUE_FORMAT;
  }
  if (!isTagValue(tagValue)) {
    throw new Error(
      `Field ${field} in device ${device.did} is not a TagValue field, so does not have a timestamp`
    );
  }
  return handler(tagValue);
};

const withNumericFieldValue = (
  params: FormatParams,
  handler: NumericFieldValueHandler
) => withFieldValue(params, (value) => handler(+value));

const dateFormatter: Formatter = (params) =>
  withFieldValue(params, (value) =>
    formatShortDateTime(new Date(value.toString()))
  );

const numberFormatter =
  (precision: number, units = ''): Formatter =>
  (params) =>
    withNumericFieldValue(
      params,
      (value) => `${value.toFixed(precision)}${units}`
    );

const durationFormatter: Formatter = (params) =>
  withNumericFieldValue(params, (value) =>
    formatDuration(
      intervalToDuration({
        start: 0,
        end: value * 1000,
      })
    )
  );

const MAX_BATT = 4.1;
const MIN_BATT = 3.4;
const batteryLevelFormatter: Formatter = (params) =>
  withNumericFieldValue(params, (value) => {
    const percentage = ((value - MIN_BATT) / (MAX_BATT - MIN_BATT)) * 100;
    return `${value.toFixed(2)}V (${percentage.toFixed(0)}%)`;
  });

const fieldTimestampFormatter: Formatter = (params) =>
  withTagValue(params, ({ startTime }) =>
    formatShortDateTime(new Date(startTime))
  );

const FAILURE_REASONS: { [id: number]: string } = {
  0: '-',
  1: 'Could not match phase angle',
  2: 'Too much variation to determine scale',
  3: 'Could not match plug changes to sensor',
  4: 'Not enough change to estimate wire resistance',
  5: 'Not enough changes to proceed with calibration',
};
const failureReasonFormatter: Formatter = (params) =>
  withNumericFieldValue(params, (value) => FAILURE_REASONS[value]);

const DEGREES = '°';
const OHMS = 'Ω';
const AMPERAGE = 'A';
const FORMATTERS: { [key in FormattableField]?: Formatter } = {
  average_ble_retry: numberFormatter(2),
  average_rars: numberFormatter(0),
  average_rssi: numberFormatter(0),
  installed_on: dateFormatter,
  last_battery_time: dateFormatter,
  last_factor_time: dateFormatter,
  sats: durationFormatter,
  batt: batteryLevelFormatter,
  battery: batteryLevelFormatter,
  uptm: durationFormatter,
  init: fieldTimestampFormatter,
  ptmp: numberFormatter(2, DEGREES),
  failreason: failureReasonFormatter,
  wireresistance: numberFormatter(3, OHMS),
  maxchange: numberFormatter(2, AMPERAGE),
  snr: numberFormatter(0),
  pecorrectedavgsignal: numberFormatter(2),
  perwatt: numberFormatter(1),
  scaleuncertainty: numberFormatter(3),
  phaseuncertainty: numberFormatter(1, DEGREES),
  calibrationchange: numberFormatter(1),
  phasediscrepancy: numberFormatter(1),
  allplugsscore: numberFormatter(0),
};

const defaultFormatter: Formatter = (params) =>
  withFieldValue(params, (value) => value.toString());

export const formatValueForDevice = ({ device, field }: FormatParams) =>
  (FORMATTERS[field] ?? defaultFormatter)({ device, field });
