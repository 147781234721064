import Layout from '@app/components/layout/Layout';
import { APP_TITLE } from '@app/config/titles';
import DateFnsUtils from '@date-io/date-fns';
import {
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { defaultTheme } from 'theme';
import RequireAuth from './components/auth/RequireAuth';
import {
  AccountPage,
  LoginPage,
  LogoutPage,
  RecentInstallationsPage,
} from './pages';
import { store } from './store';

export const theme = responsiveFontSizes(defaultTheme);

const App = () => (
  <>
    <CssBaseline />

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StoreProvider store={store}>
        <HelmetProvider>
          <Helmet>
            <title>{APP_TITLE}</title>
          </Helmet>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <Router>
                <Layout>
                  <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route
                      path="/account"
                      element={
                        <RequireAuth>
                          <AccountPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/"
                      element={
                        <RequireAuth>
                          <RecentInstallationsPage />
                        </RequireAuth>
                      }
                    />
                  </Routes>
                </Layout>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </HelmetProvider>
      </StoreProvider>
    </MuiPickersUtilsProvider>
  </>
);

export default App;
